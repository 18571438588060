import React, { useState, useEffect } from "react";
import BookCard from "./BookCard";
import LottieLoader from "./LottieLoader";

const BookDisplay = ({ book, isLoading, onError }) => {
  const [hasLoadingTakenTooLong, setHasLoadingTakenTooLong] = useState(false);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        setHasLoadingTakenTooLong(true);
      }, 20000);
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  if (hasLoadingTakenTooLong) {
    throw new Error("Loading took too long");
  }

  return (
    <div>{isLoading ? <LottieLoader /> : book && <BookCard book={book} />}</div>
  );
};

export default BookDisplay;
