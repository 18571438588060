import React from "react";
import Lottie from "react-lottie";
import { Button } from "@aws-amplify/ui-react";
import animationData from "../lotties/lottie-bookscan.json";
import "./BookscanHero.css";

function BookscanHero({ email, signOut }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="hero-container">
      <div className="hero-lottie">
        <Lottie options={defaultOptions} />
      </div>
      <div className="hero-text">
        <p className="user-email">You're logged in as: {email}</p>
        <Button onClick={signOut} className="signOutButton">
          Sign Out
        </Button>
      </div>
    </div>
  );
}

export default BookscanHero;
