// ErrorBoundaryWrapper.js
import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

const ErrorBoundaryWrapper = (props) => {
  const navigate = useNavigate();

  return <ErrorBoundary navigate={navigate} {...props} />;
};

export default ErrorBoundaryWrapper;
