import { useEffect } from "react";
import { generateClient } from "@aws-amplify/api";
import * as graphqlSubscriptions from "../graphql/subscriptions";

const client = generateClient();

const useSubscriptions = (setBook, onSubscriptionComplete) => {
  useEffect(() => {
    const subscriptionObjects = [];

    const handleNewBook = (newBook) => {
      setBook(newBook);
      onSubscriptionComplete();
    };

    const handleUpdatedBook = (updatedBook) => {
      setBook(updatedBook);
      onSubscriptionComplete();
    };

    const onCreateBookSubscription = client
      .graphql({ query: graphqlSubscriptions.onCreateBook })
      .subscribe({
        next: (response) => {
          const { data } = response;
          if (data && data.onCreateBook) {
            handleNewBook(data.onCreateBook);
          }
        },
        error: (error) => console.error(error),
      });
    subscriptionObjects.push(onCreateBookSubscription);

    const onUpdateBookSubscription = client
      .graphql({ query: graphqlSubscriptions.onUpdateBook })
      .subscribe({
        next: (response) => {
          console.log("onUpdateBook subscription value:", response);
          const { data } = response;
          if (data && data.onUpdateBook) {
            handleUpdatedBook(data.onUpdateBook);
          } else {
            console.error("Unexpected subscription data format:", response);
          }
        },
        error: (error) => console.error(error),
      });
    subscriptionObjects.push(onUpdateBookSubscription);

    return () => {
      subscriptionObjects.forEach((subscription) => {
        if (subscription && typeof subscription.unsubscribe === "function") {
          subscription.unsubscribe();
        }
      });
    };
  }, [setBook, onSubscriptionComplete]);
};

export default useSubscriptions;
