// ArchDiagram.js
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "./ArchDiagram.css";

function ArchDiagram() {
  const imageUrl = process.env.PUBLIC_URL + "/Architectural-Diagram.png";

  return (
    <div className="arch-diagram-page">
      <Header />
      <div className="diagram-container">
        <img
          src={imageUrl}
          alt="Architecture Diagram"
          className="architecture-diagram"
        />
      </div>
      <Footer />
    </div>
  );
}

export default ArchDiagram;
