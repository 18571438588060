import React from "react";
import { Card, Image, Flex, Text, Rating, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import LottieLoader from "./LottieLoader";
import "./BookCard.css";

class BookCard extends React.Component {
  state = {
    expanded: false,
  };

  toggleExpand = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  renderStarRating(ratingAverage) {
    return (
      <Rating
        value={ratingAverage}
        maxValue={5}
        size="large"
        fillColor="gold"
        emptyColor="gray"
      />
    );
  }
  renderTextRating(label, rating) {
    return (
      <Text className="text-rating">
        {label}: {rating} / 5
      </Text>
    );
  }
  renderGenresOrMoods(attribute) {
    return attribute
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
  }

  renderDescription(description, expanded) {
    if (expanded) {
      return description;
    }
    return description.length > 300
      ? description.slice(0, 300) + "..."
      : description;
  }

  render() {
    const { book, isLoading } = this.props;
    const { expanded } = this.state;

    if (isLoading) {
      return <LottieLoader />;
    }
    if (!book) return null;

    return (
      <Card variation="elevated" className="book-card">
        <Flex direction="row" justifyContent="space-between">
          <Flex
            direction="column"
            padding="1rem"
            gap="0.5rem"
            className="book-details"
          >
            <Text as="h2" className="book-title">
              {book.title}
            </Text>
            {book.subtitle && (
              <Text as="h3" className="book-subtitle">
                {book.subtitle}
              </Text>
            )}
            <Text as="p" className="book-author">
              by {book.author}
            </Text>
            <Text></Text>
            <Text className="rating-section-title">
              {" "}
              Book Whisperer Rating:
            </Text>
            {this.renderStarRating(parseFloat(book.ratingAverage))}
            {book.ratingGoodreads &&
              this.renderTextRating("Goodreads Rating", book.ratingGoodreads)}
            {book.ratingStorygraph &&
              this.renderTextRating("StoryGraph Rating", book.ratingStorygraph)}
            <div className="published-container">
              <Text className="book-published">
                Published:{" "}
                <span className="book-date">{book.publishedDate}</span>
              </Text>
            </div>
            <Text className="book-genre">
              Genre: {this.renderGenresOrMoods(book.genre)}
            </Text>
            <Text className="book-mood">
              Mood: {this.renderGenresOrMoods(book.mood)}
            </Text>
            <Text className="book-pace">Pace: {book.pace.toUpperCase()}</Text>
            <div
              className={
                expanded ? "book-description expanded" : "book-description"
              }
            >
              {this.renderDescription(book.description, expanded)}
            </div>
            <Button
              as="span"
              className="toggle-description"
              onClick={this.toggleExpand}
            >
              {expanded ? "Show Less" : "Show More"}
            </Button>
          </Flex>
          <Image
            src={book.cover_url}
            alt={`Cover of ${book.title}`}
            className="book-cover-image"
          />
        </Flex>
      </Card>
    );
  }
}

export default BookCard;
