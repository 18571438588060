import React from "react";
import Lottie from "react-lottie";
import errorAnimation from "../lotties/lottie-error.json";
import { Link } from "react-router-dom";
import "./ErrorComponent.css";

const ErrorComponent = ({ errorMessage }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="error-container">
      <div className="error-lottie">
        <Lottie options={defaultOptions} />
      </div>
      <div className="error-text">
        <h1>Ooops! an error occurred.</h1>
        <p>
          Something went wrong. Did you try scanning the ISBN instead of the
          front cover?
        </p>
        <p>
          If the error persists, it is likely we don't have information on this
          book yet :(
        </p>
        <p>
          <Link to="/home">Navigate back to the home page</Link>
        </p>
      </div>
    </div>
  );
};

export default ErrorComponent;
