// PrivateRoute.js
import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";

const PrivateRoute = ({ children }) => {
  return (
    <Authenticator>
      {({ signOut, user }) => (user ? children : null)}
    </Authenticator>
  );
};

export default PrivateRoute;
