/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBookMetaData = /* GraphQL */ `
  subscription OnCreateBookMetaData(
    $filter: ModelSubscriptionBookMetaDataFilterInput
  ) {
    onCreateBookMetaData(filter: $filter) {
      id
      Book {
        id
        title
        subtitle
        author
        authorID
        description
        genre
        ratingAverage
        ratingGoodreads
        ratingStorygraph
        mood
        pace
        publishedDate
        publisher
        pageCount
        cover_url
        isbn13
        maturityRating
        createdAt
        updatedAt
        __typename
      }
      infoLink
      previewLink
      canonicalVolumeLink
      industryIdentifiers
      imageLinks
      createdAt
      updatedAt
      bookMetaDataBookId
      __typename
    }
  }
`;
export const onUpdateBookMetaData = /* GraphQL */ `
  subscription OnUpdateBookMetaData(
    $filter: ModelSubscriptionBookMetaDataFilterInput
  ) {
    onUpdateBookMetaData(filter: $filter) {
      id
      Book {
        id
        title
        subtitle
        author
        authorID
        description
        genre
        ratingAverage
        ratingGoodreads
        ratingStorygraph
        mood
        pace
        publishedDate
        publisher
        pageCount
        cover_url
        isbn13
        maturityRating
        createdAt
        updatedAt
        __typename
      }
      infoLink
      previewLink
      canonicalVolumeLink
      industryIdentifiers
      imageLinks
      createdAt
      updatedAt
      bookMetaDataBookId
      __typename
    }
  }
`;
export const onDeleteBookMetaData = /* GraphQL */ `
  subscription OnDeleteBookMetaData(
    $filter: ModelSubscriptionBookMetaDataFilterInput
  ) {
    onDeleteBookMetaData(filter: $filter) {
      id
      Book {
        id
        title
        subtitle
        author
        authorID
        description
        genre
        ratingAverage
        ratingGoodreads
        ratingStorygraph
        mood
        pace
        publishedDate
        publisher
        pageCount
        cover_url
        isbn13
        maturityRating
        createdAt
        updatedAt
        __typename
      }
      infoLink
      previewLink
      canonicalVolumeLink
      industryIdentifiers
      imageLinks
      createdAt
      updatedAt
      bookMetaDataBookId
      __typename
    }
  }
`;
export const onCreateAuthor = /* GraphQL */ `
  subscription OnCreateAuthor($filter: ModelSubscriptionAuthorFilterInput) {
    onCreateAuthor(filter: $filter) {
      id
      name
      Author {
        nextToken
        __typename
      }
      bio
      quotes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuthor = /* GraphQL */ `
  subscription OnUpdateAuthor($filter: ModelSubscriptionAuthorFilterInput) {
    onUpdateAuthor(filter: $filter) {
      id
      name
      Author {
        nextToken
        __typename
      }
      bio
      quotes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuthor = /* GraphQL */ `
  subscription OnDeleteAuthor($filter: ModelSubscriptionAuthorFilterInput) {
    onDeleteAuthor(filter: $filter) {
      id
      name
      Author {
        nextToken
        __typename
      }
      bio
      quotes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBook = /* GraphQL */ `
  subscription OnCreateBook($filter: ModelSubscriptionBookFilterInput) {
    onCreateBook(filter: $filter) {
      id
      title
      subtitle
      author
      authorID
      description
      genre
      ratingAverage
      ratingGoodreads
      ratingStorygraph
      mood
      pace
      publishedDate
      publisher
      pageCount
      cover_url
      isbn13
      maturityRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBook = /* GraphQL */ `
  subscription OnUpdateBook($filter: ModelSubscriptionBookFilterInput) {
    onUpdateBook(filter: $filter) {
      id
      title
      subtitle
      author
      authorID
      description
      genre
      ratingAverage
      ratingGoodreads
      ratingStorygraph
      mood
      pace
      publishedDate
      publisher
      pageCount
      cover_url
      isbn13
      maturityRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBook = /* GraphQL */ `
  subscription OnDeleteBook($filter: ModelSubscriptionBookFilterInput) {
    onDeleteBook(filter: $filter) {
      id
      title
      subtitle
      author
      authorID
      description
      genre
      ratingAverage
      ratingGoodreads
      ratingStorygraph
      mood
      pace
      publishedDate
      publisher
      pageCount
      cover_url
      isbn13
      maturityRating
      createdAt
      updatedAt
      __typename
    }
  }
`;
