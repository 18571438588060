import React, { useState, useEffect } from "react";
import { View } from "@aws-amplify/ui-react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchUserAttributes } from "aws-amplify/auth";
import BookscanHero from "../components/BookscanHero";
import BookDisplay from "../components/BookDisplay";
import useSubscriptions from "../hooks/useSubscriptions";
import useHubListener from "../hooks/useHubListener";
import Header from "../components/Header";
import CameraButton from "../components/CameraButton";
import Footer from "../components/Footer";
import animationData from "../lotties/lottie-bookscan.json";
import "./Bookscan.css";

function Bookscan() {
  const [book, setBook] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, signOut } = useAuthenticator();

  useSubscriptions(setBook, () => setIsLoading(false));
  useHubListener();

  const handleUploadStart = () => {
    setIsLoading(true);
    setError(null);
  };

  const handleUploadComplete = () => {};

  const handleError = (errorObject) => {
    setError(errorObject);
  };

  const [email, setEmail] = useState("");

  useEffect(() => {
    handleFetchUserAttributes();
  }, []);

  async function handleFetchUserAttributes() {
    try {
      const attributes = await fetchUserAttributes();
      if (attributes.email) {
        setEmail(attributes.email);
      } else if (attributes.identities) {
        const identities = JSON.parse(attributes.identities);
        const googleIdentity = identities.find(
          (identity) => identity.providerName === "Google"
        );
        if (googleIdentity) {
          setEmail(googleIdentity.userId);
        }
      }
    } catch (error) {
      console.error("Error fetching user attributes", error);
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <View className="App">
      <Header />
      <div className="section">
        <CameraButton
          onUploadStart={handleUploadStart}
          onUploadComplete={handleUploadComplete}
        />
        <BookDisplay book={book} isLoading={isLoading} onError={handleError} />
      </div>
      <div className="section">
        <BookscanHero email={email} signOut={signOut} />
      </div>
      <Footer />
    </View>
  );
}

export default Bookscan;
