import React from "react";
import Lottie from "react-lottie";
import animationData from "../lotties/lottie-book-turn.json";
import "./HowItWorks.css";

function HowItWorks() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="how-container">
      <div className="how-text">
        <h1>how does it work?</h1>
        <p>
          Under the hood, Book Whisperer leverages Machine Learning and
          non-intrusive techniques to scavenge the internet in search of
          insights. We are grateful to GoodReads, The StoryGraph, BookMarks,
          GoogleBooks, and other book communities for the information that feeds
          into Book Whisperer.
        </p>
      </div>
      <div className="how-lottie">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
}
export default HowItWorks;
