import React from "react";
import { View, Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <View as="footer" className="footer-container">
      <Text fontSize="small" className="footer-text">
        Book Whisperer - All Rights Reserved 2024
      </Text>
    </View>
  );
};

export default Footer;
