import { useEffect } from "react";
import { Hub } from "aws-amplify/utils";

const useHubListener = () => {
  useEffect(() => {
    const hubListenerCancel = Hub.listen("api", (data) => {
      const { payload } = data;
      console.log("Received an event from Hub:", payload);
    });

    return () => {
      hubListenerCancel();
    };
  }, []);
};

export default useHubListener;
