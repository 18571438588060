import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ErrorBoundaryWrapper from "./components/ErrorBoundaryWrapper";
import ErrorComponent from "./pages/ErrorComponent";
import PrivateRoute from "./components/PrivateRoute";
import Bookscan from "./pages/Bookscan";
import ArchDiagram from "./pages/ArchDiagram";

function App() {
  return (
    <Router>
      <ErrorBoundaryWrapper>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/error" element={<ErrorComponent />} />
          <Route path="/architecture" element={<ArchDiagram />} />
          <Route
            path="/bookscan"
            element={
              <PrivateRoute>
                <Bookscan />
              </PrivateRoute>
            }
          />

          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
      </ErrorBoundaryWrapper>
    </Router>
  );
}

export default App;
