import React from "react";
import Lottie from "react-lottie";
import animationData from "../lotties/lottie-thinker-book.json";
import "./HeroWithLottie.css";

function HeroWithLottie() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="hero-container">
      <div className="hero-text">
        <h1>cultivate your library with intelligence</h1>
        <p>Ditch book duds. Build a library you'll adore.</p>
        <p>
          Scan book cover or ISBN, analyze, enjoy. Smart insights power your
          perfect reading journey.
        </p>
      </div>
      <div className="hero-lottie">
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
}

export default HeroWithLottie;
