import React from "react";
import "./PrivacyPolicy.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <Header />
      <div className="privacy-content">
        <h1>Privacy Policy for Book Whisperer</h1>

        <h2>Introduction</h2>
        <p>
          Welcome to Book Whisperer. We are dedicated to safeguarding your
          privacy and managing your data with transparency and integrity. This
          Privacy Policy outlines our practices regarding the collection, use,
          processing, and disclosure of your data, including personal data, in
          relation to your access and use of Book Whisperer.
        </p>

        <h2>Data Collection and Use</h2>
        <h3>Information You Provide</h3>
        <ul>
          <li>
            <strong>Account Information</strong>: We use your Gmail account
            details solely for the purpose of account creation and login.
          </li>
          <li>
            <strong>Book Data</strong>: When you use Book Whisperer to scan or
            upload book covers, we process this data to provide you insights
            about the books.
          </li>
        </ul>

        <h3>Use of Your Data</h3>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li>
            <strong>Account Management</strong>: To create and manage your Book
            Whisperer account.
          </li>
          <li>
            <strong>Service Provision</strong>: To analyze the book covers you
            upload and generate insights.
          </li>
          <li>
            <strong>Book Data Deletion</strong>: Book covers uploaded are
            automatically deleted from our servers within 24 hours.
          </li>
        </ul>

        <h2>Data Sharing and Disclosure</h2>
        <p>
          We do not share your personal data or any uploaded content with
          third-party service providers. Your data is used exclusively for the
          services provided by Book Whisperer.
        </p>

        <h2>Data Security</h2>
        <p>
          We employ appropriate security measures to protect against
          unauthorized access to or unauthorized alteration, disclosure, or
          destruction of data.
        </p>

        <h2>Your Data Protection Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal data
          held by Book Whisperer. You can exercise these rights at any time by
          contacting us.
        </p>

        <h2>Children's Privacy</h2>
        <p>
          Book Whisperer is not intended for use by individuals under the age of
          13. We do not knowingly collect personal identifiable information from
          children under 13.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy periodically. We will notify you of
          any significant changes by posting the new policy on this page.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or
          your data's treatment, please contact us at
          ali.marhoon.work@gmail.com.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
