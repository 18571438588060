import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Header from "../components/Header";
import HeroWithLottie from "../components/HeroWithLottie";
import ScanCTA from "../components/ScanCTA";
import HowItWorks from "../components/HowItWorks";
import Footer from "../components/Footer";

function Home() {
  return (
    <div className="Home">
      <Header />
      <Swiper
        modules={[Pagination, Navigation]}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        className="mySwiper"
      >
        <SwiperSlide>
          <HeroWithLottie />
        </SwiperSlide>
        <SwiperSlide>
          <ScanCTA />
        </SwiperSlide>
        <SwiperSlide>
          <HowItWorks />
        </SwiperSlide>
      </Swiper>
      <Footer />
    </div>
  );
}

export default Home;
