/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_appsync_graphqlEndpoint:
    "https://k4i4zvnve5cltmiuasiim3ghtu.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-2y44eco5lrebxldavdsbr27sqm",
  aws_cognito_identity_pool_id:
    "us-east-1:92a53b68-f673-4360-b3f8-e16eb74adf4a",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_ky234Vkuj",
  aws_user_pools_web_client_id: "3kdmg95ptqhe1ut7jdf4l4a6u6",
  oauth: {
    domain:
      "ketabklikamplifyreac9a36c4b1-9a36c4b1-staging.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://www.book-whisperer.com",
    redirectSignOut: "https://www.book-whisperer.com",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "ketabklik-image-bucket05804-staging",
  aws_user_files_s3_bucket_region: "us-east-1",
  predictions: {
    identify: {
      identifyText: {
        proxy: false,
        region: "us-east-1",
        defaults: {
          format: "PLAIN | FORM | TABLE | ALL",
        },
      },
    },
  },
};

export default awsmobile;
