// Header.js
import React from "react";
import { View, Image, Menu, MenuItem } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

function Header() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <View as="header" className="header">
      <Menu menuAlign="start" className="header-menu">
        <MenuItem onClick={() => handleNavigation("/home")}>Home</MenuItem>
        <MenuItem onClick={() => handleNavigation("/bookscan")}>
          Bookscan
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/privacy-policy")}>
          Privacy Policy
        </MenuItem>
        <MenuItem onClick={() => handleNavigation("/architecture")}>
          Architectural Diagram
        </MenuItem>
      </Menu>
      <View
        className="logo"
        onClick={() => handleNavigation("/home")}
        style={{ cursor: "pointer" }}
      >
        <Image
          src="/book-whisperer-brand/svg/logo-no-background.svg"
          alt="Book Whisperer Logo"
        />
      </View>
    </View>
  );
}

export default Header;
