import React, { useRef } from "react";
import { Button } from "@aws-amplify/ui-react";
import { uploadData } from "aws-amplify/storage";
import "./CameraButton.css";

const CameraButton = ({ onUploadStart, onUploadComplete }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    onUploadStart();

    const file = event.target.files[0];
    const filename = `uploads/${file.name}`;

    try {
      await uploadData({
        key: filename,
        data: file,
      });
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      onUploadComplete();
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="CameraButtonContainer">
        <Button className="CameraButton" onClick={handleClick}>
          Scan Book
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </>
  );
};

export default CameraButton;
