import Lottie from "react-lottie";
import animationData from "../lotties/lottie-loading-book.json";

function LottieLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height="50%"
      width="50%"
      className="fullscreen-loader"
    />
  );
}

export default LottieLoader;
