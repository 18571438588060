import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";
import "./ScanCTA.css";

function ScanCTA() {
  const navigate = useNavigate();

  const redirectToScan = () => {
    navigate("/bookscan");
  };

  return (
    <div className="scan-cta-container" onClick={redirectToScan}>
      <h2 className="scan-cta-text">
        Ready to discover your next favorite book?
      </h2>
      <Button
        variation="primary"
        className="scan-cta-button"
        onClick={redirectToScan}
      >
        Scan Now
      </Button>
    </div>
  );
}

export default ScanCTA;
